import React from 'react';
import {MDXProvider} from "@mdx-js/react"
import Highlight, { defaultProps } from 'prism-react-renderer';

import Prism from "prism-react-renderer/prism";
import prismtheme from 'prism-react-renderer/themes/vsDark';

import '../styles/style.scss';
import Helmet from './helmet';
import Header from './header';
import Footer from './footer';

(typeof global !== "undefined" ? global : window).Prism = Prism;

require("prismjs/components/prism-lua");

const Layout = ({ children }) => (
	<div>
		<Helmet />
		<Header />
		<section className="section">
    	    <div className="container">
				
				<MDXProvider
					components={{
						h1: props => <h1 {...props} className="title is-size-4 is-capitalized" />,
						h2: props => <h1 {...props} className="title is-size-5 is-capitalized" />,
						h3: props => <h1 {...props} className="title is-size-6 is-capitalized" />,
						pre: (props) => {
							const className = props.children.props.className || '';
							const matches = className.match(/language-(?<lang>.*)/);
							return (
								<p>
							  <Highlight
								{...defaultProps}
								theme={prismtheme}
								code={props.children.props.children}
								language={
								  matches && matches.groups && matches.groups.lang
									? matches.groups.lang
									: ''
								}
							  >
								{({ className, style, tokens, getLineProps, getTokenProps }) => (
								  <pre className={className} style={style}>
									{tokens.map((line, i) => (
									  <div {...getLineProps({ line, key: i })}>
										{line.map((token, key) => (
										  <span {...getTokenProps({ token, key })} />
										))}
									  </div>
									))}
								  </pre>
								)}
							  </Highlight>
								</p>
							);							
						}
					}}
				>
					{children}
				</MDXProvider>
			</div>
		</section>
		<Footer />
	</div>
);

export default Layout;
